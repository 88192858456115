interface TimeSlot {
  label: string;
  start: Date;
  end: Date;
  active: boolean
}

export function getTimeSlots(
  selectedDate?: Date,
  startHour = 10,
  endHour = 17,
  interval = 30
): TimeSlot[] {
  const slots: TimeSlot[] = [];

  let isToday: boolean;
  if (!selectedDate) {
    const today: Date = new Date();
    const currentDate: number = today.getDate();
    const currentMonth: number = today.getMonth();
    const currentYear: number = today.getFullYear();

    selectedDate = new Date(currentYear, currentMonth, currentDate);

    isToday = true;
  } else {
    const today: Date = new Date();
    const currentDate: number = today.getDate();
    const currentMonth: number = today.getMonth();
    const currentYear: number = today.getFullYear();

    isToday =
      selectedDate.getDate() === currentDate &&
      selectedDate.getMonth() === currentMonth &&
      selectedDate.getFullYear() === currentYear;
  }

  let startTime: Date;
  if (isToday) {
    const currentHour: number = new Date().getHours();
    const currentMinute: number = new Date().getMinutes();

    if (
      currentHour < startHour ||
      (currentHour === startHour && currentMinute < interval)
    ) {
      startTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        startHour,
        0,
        0
      );
    } else if (currentHour >= endHour) {
      return slots; // No slot is possible after the endHour
    } else if (currentMinute >= interval) {
      startTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        currentHour + 1,
        0,
        0
      );
    } else {
      startTime = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth(),
        selectedDate.getDate(),
        currentHour,
        interval,
        0
      );
    }
  } else {
    startTime = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      startHour,
      0,
      0
    );
  }

  const endTime: Date = new Date(startTime);
  endTime.setHours(endHour, 0, 0, 0);

  while (startTime < endTime) {
    const slot: TimeSlot = {
      label: formatTime(startTime),
      start: new Date(startTime),
      end: new Date(startTime.getTime() + interval * 60000), // Add interval minutes to the start time
      active: false
    };

    slots.push(slot);

    startTime.setTime(startTime.getTime() + interval * 60000); // Add interval minutes to the start time
  }

  return slots;
}
function formatTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}
