<template>
  <div class="bg-white py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl sm:text-center">
        <h1
          class="mb-4 sm:py-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
        >
        Meet Our
          <span class="text-skin-dodger/80 dark:text-skin-dodger/50">Team</span>
        </h1>
        <p class="mt-6 text-lg leading-8 text-gray-600">We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.</p>
      </div>
      <ul role="list" class="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
        <li v-for="person in people" :key="person.name" class="items-center border border-l flex flex-col gap-6 p-4 rounded-2xl xl:flex-row">
          <img class="aspect-sqaure h-72 w-64 flex-none rounded-2xl object-cover" :src="person.imageUrl" alt="" v-if="person.imageUrl"/>
          <div class="flex-auto text-justify">
            <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.name }}</h3>
            <p class="text-base leading-7 text-gray-600">{{ person.role }}</p>
            <p class="mt-6 text-base leading-7 text-gray-600">{{ person.bio.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamPage",
};
</script>
<script setup>
const people = [
  {
    name: 'CA Prabhakar',
    role: 'Founder Partner',
    imageUrl:
      require('@/assets/prabhakar.jpeg'),
    bio:{
      main: "CA Prabhakar (B.COM, FCA, DISA, DITA, DIRM)",
      specialisation:"Direct Tax",
      description:"Mr CA Prabhakar is a co-founder of P V C & Associates. He is standing with 8 years of experience. He has wide experience in the field of Direct Tax, Assurance and Accounting. His strength lies in maintaining integrity and delivering client satisfying services. He has held leadership roles and has been in the forefront in articulating group philosophy and values and believes in a team building approach. He has been involved in various social activities throughout his life apart from his profession.",
      sub_description:"He is a certified in Concurrent Audit, Forensic Audit, Public Accounting, IBC, Arbitration, mediation and conciliation etc.,  "

    },
  },
  {
    name: 'CA Vennala',
    role: 'Founder Partner',
    imageUrl:
      require('@/assets/vennala.jpeg'),
    bio:{
      description:"Mrs. CA Vennela qualified as a CA with well versed in Audit and Assurance related works. She manages Tax Compliance and Represents at all I.T. Department levels. She is a passionate tax practitioner. She has more than 7 years of Industry experience of handling Corporate Finance / Secretarial Matters of companies listed on Stock Exchange.",
      sub_description:"She is a certified in Concurrent Audit, Forensic Audit, IBC., and she is holding  independent directorship from Indian Institute of Corporate Affairs"

    },
  },
  {
    name: 'Mr. Praveen',
    role: 'Senior Audit Manager',
    imageUrl:
      require('@/assets/praveen.png'),
    bio:{
      description:"He is standing with 10 years of Experience in the field of Direct Tax, Assurances and Accounting. His strength lies in maintaining integrity and delivering client satisfying services. He has held leadership roles and has been in the forefront in articulating group philosophy and values and believes in a team building approach. He has involved in various social activities throughout his life apart from his profession.",
    },
  },
  {
    name: 'Mrs. Srimalika',
    role: 'Senior HR Administration Manager',
    imageUrl:
      require('@/assets/srimalika.png'),
    bio:{
      description:"She is qualified Administration person and handles all the Admin and HR related Activities in both Hyderabad and Nizmabad office. She is one of the strong pillars to the organization",
    },
  },
  {
    name: 'Mrs. Rasa Rakshitha',
    role: 'Sr Accountant',
    imageUrl:
    require('@/assets/rakshitha.png'),
    bio:{
      description:"Rakshitha is graduated in M.com. She is entrusted with the following duties in the firm. Finalization of financial statements, Notes to Accounts, Explanatory Notes, drafting audit Reports and CARO, Verification of Statutory Compliances related to GST, TDS, Income Tax provisions Accounting and Auditing Standards.",
    },
  },
  {
    name: 'Mrs.Kaligota Ruchitha',
    role: 'Sr Accountant',
    imageUrl:
    require('@/assets/ruchitha.png'),
    bio:{
      description:"Ruchitha is graduated in M.com. She is entrusted with the following duties in the firm. Income Tax filing, accounting, GST, audit, registration and certification, MSME loans, mergers & acquisitions, financial accounting conversions.",
    },
  },
  {
    name: 'K Vinod Kumar',
    role: 'Sr Accountant',
    imageUrl:
    '',
    bio:{
      description:"He is qualified Administration person and handles all the Admin and HR related Activities in both Hyderabad and Nizmabad office. She is one of the strong pillars to the organization",
    },
  },
  {
    name: 'G. Chandra Reddy',
    role: 'Accountant',
    imageUrl:
    '',
    bio:{
      description:"Chandra is qualified MBA graduate, he Support in handling administrative responsibilities for clients allocated to him such as engagement letters, filing of client papers, billing follow-up, etc... He conducts the engagement in a professional manner and in accordance with client expectations.",
    },
  },
  {
    name: 'Sangem Saiteja',
    role: 'Paid Assistant  ',
    imageUrl:
    '',
    bio:{
      description:"Sai Teja is qualified B.com graduate and pursing professional course (CMA). He is entrusted with the following duties in the firm Income tax filing, accounting, GST, audits, registration and certification, MSME loans, mergers & acquisitions, financial accounting conversions.",
    },
  },
]
</script>