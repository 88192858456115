export const home = {
  main_heading: "Welcome to",
  main_description:
    "We are a team of experienced Chartered Accountants dedicated to providing high-quality services to our clients. Our goal is to help individuals and businesses achieve their financial objectives by providing tailored solutions that meet their unique needs."
};

export const common = {
  learn_more: "Learn More"
};

export const brand = "P V C & Associates";

export const services = [
  {
    name: "Taxation",
    to: "services/taxation",
    image: require("../assets/services/taxation.png"),
    description:
      "Get personalized tax solutions for individuals and businesses with expert taxation services. Ensure compliance and minimize liabilities with tax planning and audit support. Achieve financial goals with comprehensive tax services."
  },
  {
    name: "Registration",
    to: "services/registration",
    image: require("../assets/services/registration.png"),
    description:
      "Ensure compliance with legal requirements for your business with our personalized registration solutions. Our experienced accountants guide you through the process and stay updated on the latest regulations."
  },
  {
    name: "Notices and Scrutiny",
    to: "services/notices-scrutiny",
    image: require("../assets/services/security.png"),
    description:
      "As Income Tax Consultant, we provide all sort of services relating to Income Tax matters. We are expert in getting assessment done, appearance before CIT (Appeal) & ITAT."
  },
  {
    name: "Auditing",
    to: "services/auditing",
    image: require("../assets/services/auditing.png"),
    description:
      "We assure a systematic and independent examination of your financial records. Our team is well qualified to efficiently audit your books and identify the anomalies and red flags that might be deeply buried in thousands of electronic transactions. "
  },
  {
    name: "Corporate Advisory",
    to: "services/corporate-advisory",
    image: require("../assets/services/advisory.png"),
    description:
      "We’ll work with you to maximise your Organisational Value. We assert that success of a business largely hinges on the effectiveness of top-level governance."
  }
];

export const taxation = [
  {
    name: "Registraction",
    to: "services/taxation/registration",
    description:
      "Income Tax and GST registrations for all assesses, and 12A/80G registrations for Trusts."
  },
  {
    name: "Tax Planning",
    to: "services/taxation/tax-planning",
    description:
      "Strategic Planning and advisory services for your Income tax and GST issues before hand."
  },
  {
    name: "International Taxation & Transfer Pricing",
    to: "services/taxation/internal-taxation-transfer-pricing",
    description:
      "International taxation issues as per Income tax and GST, DTAA applicability, Transfer Pricing audit etc."
  },
  {
    name: "Returns Filing",
    to: "services/taxation/returns-filing",
    description:
      "Periodic return filing of Income tax, GST & TDS for Individuals, HUF, Companies, LLP, Firms and Trust."
  },
  {
    name: "Certification & Tax Audits",
    to: "services/taxation/certification-tax-audit",
    description:
      "Comprehensive Income tax and GST Audits. Certification of documents as per the norms."
  }
];

export const registration = [
  {
    name: "Proprietorship Registration",
    to: "services/registration/proprietorship",
    description:
      "Proprietorship Firm is most common structure opted by merchants and service providers. A sole proprietor becomes single owner of business. The law treats sole proprietor and his business as same and he is held personally liable for all business debts. The low cost of registration, minimum compliances make it a desirable option for small businesses."
  },
  {
    name: "Partnership Registration",
    to: "services/registration/partnership",
    description:
      "Partnership Registration is necessary for any association of people to join for a business purpose and continue with legal bindings,  sharing their profits according to law. We are giving the best services for Partnership Registration in Bangalore. We prepare Partnership Deed with the help of professionals according to the requirement of the customer and register the Partnership Firm with Registrar of Firms on behalf of the customer."
  },
  {
    name: "One Person Company Registration",
    to: "services/registration/one-person-company",
    description:
      "A One Person Company is a company with a single member. It was introduced by the Companies Act, 2013. OPC extends the concept of limited liability to a company run by a single person. It is similar in respects to a private limited company with certain differences like fewer compliances and relaxation of certain restrictions. Thus, an OPC is subject to all the provisions of the Act like a private limited company unless expressly excluded."
  },
  {
    name: "Limited Liability Partnership (LLP) Registration",
    to: "services/registration/limited-liability",
    description:
      "Limited Liability Partnership (LLP) was introduced in India by way of the Limited Liability Partnership Act, 2008. The basic premise behind the introduction of Limited Liability Partnership (LLP) is to provide a form of business entity that is simple to maintain while providing limited liability to the owners. Since, its introduction in 2010, LLPs have been well received with over 1 lakhs registration so far until September, 2014."
  },
  {
    name: "Private Limited Company Registration",
    to: "services/registration/private-limited",
    description:
      "One of the most popular types of business entities is a Private Limited Company. A company registration in India requires a minimum of two members and directors. The directors of a private limited company have limited liability to creditors. In a case of default, banks/creditors can only sell company's assets but not personal assets of directors."
  },
  {
    name: "Nidhi Company Registration",
    to: "services/registration/nidhi",
    description:
      "Nidhi is a Hindi word, which means finance or fund. Nidhi means a company which has been incorporated with the object of developing the habit of thrift and reserve funds amongst its members and also receiving deposits and lending to its members only for their mutual benefit."
  },
  {
    name: "Section 8 Company Registration",
    to: "services/registration/section-eight",
    description:
      "Widely known as Section-8 companies, these organizations are registered under the Section-8 of Companies Act 2013. These companies intend to promote art, commerce, sports, safety, science, research, healthcare, social welfare, religion, protection of the environment, etc. The main object of these companies ensures the promotion of above-mentioned fields, provided the profit is used for promoting only objects of the company (Sec 8(1)(b)). Sec 8 (1) (c) states the prohibition of payment of any dividend to its members. Ministry of Corporate Affairs (MCA), administers and maintains the rules and regulations through ROC in all of the states of India."
  },
  {
    name: "GST Registration",
    to: "services/registration/gst",
    description:
      "Any business in India that supplies goods or services with turnover exceeding Rs. 20 lakhs (Rs. 10 lakhs for North Eastern and hill states) has to get registered under GST."
  }
];

export const auditing = [
  {
    name: "Internal Audit",
    to: "services/auditing/internal-audit",
    description:
      "We design and set up business process and controls. Perform periodic evaluation of internal controls and financial records."
  },
  {
    name: "Audit under specific laws",
    to: "services/auditing/audit-under-specific-laws",
    description:
      "Audits required under a specific statute such as Income tax law, GST law etc."
  },
  {
    name: "Performance audits",
    to: "services/auditing/performance-audits",
    description:
      "Stock Audit, Forensic Audit, Management Audit etc..."
  },
  {
    name: "Statutory Audit",
    to: "services/auditing/statutory-audit",
    description:
      "Form an independent view on the accuracy of financial statements and records as per Company Law norms."
  },
  {
    name: "Audit of special entities",
    to: "services/auditing/audit-special-entities",
    description:
      "Audit of trust, schools, societies and associations."
  },
]

export const advisory = [
  {
    name: "Right Documentation",
    to: "services/auditing/right-documentation",
    description:
      "Set the tone-at-the-top with a well-written Code of Ethics, Standard Operating Procedures, agreements, MOU’s etc."
  },
  {
    name: "Advisory and Secretarial Services",
    to: "services/auditing/advisory-secretarial-services",
    description:
      "We provide a range of corporate law and Secretarial law related services from structuring to reorganization and from regulatory to compliances."
  },
  {
    name: "Project Reports and Valuation Reports",
    to: "services/auditing/project-reports-valuation-reports",
    description:
      "Detailed project and valuation reports to ease the end user in decision making."
  },
  {
    name: "Due Diligence",
    to: "services/auditing/due-diligence",
    description:
      "We undertake financial due diligence for prospective investors/venture capitalists."
  },
]

export const navbar = [
  {
    label: "Home",
    icon: "",
    path: "/",
    active: true
  },
  {
    label: "About",
    icon: "",
    path: "/about",
    active: false
  },
  {
    label: "Services",
    icon: "",
    path: "/services",
    active: false,
    dropdown: true,
    showDropdown: false,
    dropdown_items: [
      {
        category: "services",
        name: "Taxation",
        to: "taxation",
        active: false
      },
      {
        category: "services",
        name: "Registration",
        to: "registration",
        active: false
      },
      {
        category: "services",
        name: "Notices and Scrutiny",
        to: "notices-scrutiny",
        active: false
      },
      {
        category: "services",
        name: "Auditing and Assurance",
        to: "auditing-assurance",
        active: false
      },
      {
        category: "services",
        name: "Advisory",
        to: "advisory",
        active: false
      }
    ]
  },
  {
    label: "Team",
    icon: "",
    path: "/team",
    active: false
  },
  {
    label: "Career",
    icon: "",
    path: "/career",
    active: false,
    dropdown: true,
    showDropdown: false,
    dropdown_items: [
      {
        category: "career",
        name: "Articleship",
        to: "articleship",
        active: false
      },
      {
        category: "career",
        name: "Employment",
        to: "employment",
        active: false
      }
    ]
  },
  {
    label: "Contact",
    icon: "",
    path: "/contact",
    active: false
  }
];

export const career = [
  {
    name: "Employment",
    to: "career/employment",
    description: "Apply for employement"
  },
  {
    name: "Articleship",
    to: "career/articleship",
    description: "Apply for articleship"
  }
];
