<template>
  <div
    class="max-w-screen-xl px-4 pt-32 lg:pb-4 mx-auto md:px-6 sm:pt-2 lg:justify-items-center my-16"
  >
    <h1
      class="sm:py-4 sm:pt-28 text-4xl lg:pt-36 font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
    >
      We believe in
      <span class="text-skin-dodger/80 dark:text-skin-dodger/50">Growing Together</span>
    </h1>
    <h1 class="text-4xl font-bold dark:text-white pt-4">
      We see our growth as a reflection of the success of our Clients.
    </h1>
    <p
      class="py-4 text-lg text-justify font-normal text-gray-500 lg:text-xl dark:text-gray-400"
    >
      We are Hyderabad based Chartered Accountant Firm and providing
      professional services in the field of Chartered Accountancy and
      Secretarial Services. Our Team is a group of young professionals with
      hands on experience in the field of Auditing, Finance, Accounting and
      Business Consultancy. We help the client in registration of companies, ROC
      Filings, Income Tax Filings, and Maintenance of books of accounts. We are
      dedicated in providing services to startup companies in helping them to
      setup a company and manage accounting information. We also support
      companies by providing management information system to help their
      decision making. We also deal in registration services in GST,
      Professional Tax, provident fund (PF), ESI and all other statutory
      compliances.
    </p>
    <p
      class="py-4 text-lg text-justify font-normal text-gray-500 lg:text-xl dark:text-gray-400"
    >
      The firm is a professionally managed. The team consists of distinguished
      chartered accountants, corpora represents a combination of specialized
      skills, which are geared to offers sound financial advice and personalized
      proactive services. Those associated with the firm have regular
      interaction with industry and other professionals which enables the firm
      to keep pace with contemporary developments and to meet the needs of its
      clients.
    </p>
    <router-link
      to="/services"
      class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg/70 rounded-lg hover:bg-skin-dodger/80 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
    >
      Learn More
      <svg
        class="w-5 h-5 ml-2 -mr-1"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "AboutPage",
};
</script>
