import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Services from "@/views/services/index.vue";
import Service from "@/views/services/Service.vue"
import Team from "@/views/Team.vue";
import Career from "@/views/Career.vue";
import CareerForm from "@/components/CareerForm.vue"
import Contact from "@/views/Contact.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
  },
  {
    path: "/about",
    name: "AboutPage",
    component: About,
  },
  {
    path: "/services",
    name: "ServicesPage",
    component: Services,
  },
  {
    path:"/services/:type",
    name:"ServicePage",
    component:Service
  },
  {
    path: "/career",
    name: "CareerPAge",
    component: Career,
  },
  {
    path:"/career/:type",
    name:"CareerForm",
    component:CareerForm
  },
  {
    path: "/team",
    name: "TeamPage",
    component: Team,
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: Contact,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;