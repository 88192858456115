<template>
    <div class="bg-white py-16 sm:py-26 border-t">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl">
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Our offices</h2>
          <p class="mt-6 text-lg leading-8 text-gray-600">Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.</p>
        </div>
        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div>
            <h3 class="border-l border-skin-dodger/60 pl-6 font-semibold text-gray-900">Hyderabad</h3>
            <address class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600">
              <p class="text-left">G1, Amar Apartments,<br />Bhagya Nagar,<br/>Opp. Tapasya College,<br/>KPHB Colony,<br/>Hyderabad - 500072,<br/>Phone Number: +91 82964 36529</p>
            </address>
          </div>
          <div>
            <h3 class="border-l border-skin-dodger/60 pl-6 font-semibold text-gray-900">Armoor</h3>
            <address class="border-l border-gray-200 pt-2 pl-6 not-italic text-gray-600">
              <p class="text-left">H.No: 10-112,<br />Venkateshwara Colony,<br/>Mamidipalle,<br/>Armoor,<br/>Dist: Nizamabad,<br/>Telangana - 503 224,<br/>Phone Number: +91 6281 306 201</p>
            </address>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name:"OfficeVue"
}
</script>
  