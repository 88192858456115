<template>
  <div
    class="max-w-screen-xl px-4 pt-32 lg:pb-4 mx-auto md:px-6 sm:pt-2 lg:justify-items-center my-16"
  >
    <h1
      class="mb-4 sm:py-4 sm:pt-28 lg:pt-36 font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl sm:text-4xl text-3xl dark:text-white"
    >
      {{ main_heading }}
      <span class="text-dodger-800 dark:text-dodger-500">{{ brand }}!</span>
    </h1>
    <div class="flex flex-col items-center lg:px-8 max-w-7xl mx-auto px-6">
      <img
        class="h-48 object-center object-cover rounded-lg sm:h-72 md:h-56 w-screen"
        src="../assets/home.jpg"
        alt="image description"
      />
      <div>
        <p
          class="py-8 text-lg text-justify font-normal text-gray-500 lg:text-xl dark:text-gray-400"
        >
          {{ main_description }}
        </p>
        <router-link
          to="/about"
          class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white bg-skin-dodger/60 rounded-lg hover:bg-skin-dodger/80 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
        >
          {{ learn_more }}
          <svg
            class="w-5 h-5 ml-2 -mr-1"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </router-link>
      </div>
    </div>
  </div>

  <div class="bg-white my-16 sm:pt-8 border-t">
    <div class="flex flex-col lg:px-8 max-w-7xl mx-auto px-6 items-center">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h1
          class="mb-4 py-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white"
        >
          Our
          <span class="text-skin-dodger dark:text-skin-dodger/50">Services</span>
        </h1>
      </div>
      <!-- <div
        class="mx-aut0 max-w-2xl grid-cols-1 gap-y-16 gap-x-8 border-gray-200 lg:mx-0 lg:max-w-none lg:grid-cols-2"
      > -->
      <ServiceCard :services="services" :learn_more="false"></ServiceCard>
      <!-- </div> -->
    </div>
    <router-link
      to="/services"
      class="inline-flex items-center justify-center px-5 py-3 mt-8 text-base font-medium text-center text-white bg-skin-dodger/60 rounded-lg hover:bg-skin-dodger/80 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
    >
      {{ learn_more }}
      <svg
        class="w-5 h-5 ml-2 -mr-1"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </router-link>
  </div>
  <Offices />
</template>

<script setup>
import Offices from "../components/Offices.vue";
import ServiceCard from "../components/ServiceCard.vue";
</script>
<script>
import { home, common, services, brand } from "../js/content";
export default {
  name: "HomePage",
  data() {
    return {
      ...home,
      ...common
    };
  }
};
</script>
